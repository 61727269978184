import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=cc5d63ce&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"
import style0 from "./Page.vue?vue&type=style&index=0&id=cc5d63ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc5d63ce",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeader: require('/vercel/path0/components/page/Header.vue').default,PagePreFooter: require('/vercel/path0/components/page/PreFooter.vue').default,PageFooter: require('/vercel/path0/components/page/Footer.vue').default})
